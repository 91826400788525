import React from "react"
import PropTypes from "prop-types"
import { has } from "lodash"
import { GatsbyImage } from "gatsby-plugin-image"

const Members = ({ title, list }) => (
  <section id="members" className="section section--members">
    <div className="shell">
      {title && (
        <header className="section__head">
          <h2 dangerouslySetInnerHTML={{ __html: title + `<span class="dotRed">.</span>`}} />
        </header>
      )}

      {list && (
        <div className="section__body">
          <div className="members">
            {list.map((member, index) => (
              <div key={index} className="member">
                <div className="member__content">
                  {has(member, "image") && (
                    <div className="member__avatar">
                      {member.image && (
                        <GatsbyImage
                          image={member.image.childImageSharp.gatsbyImageData}
                          alt={`Member Image ${index}`}
                        />
                      )}
                    </div>
                  )}

                  <div className="member__info">
                    <h6>{member.name}</h6>
                    {has(member, "position") && <p>{member.position}</p>}
                  </div>
                </div>

                {has(member, "description") && (
                  <div className="member__description">
                    <p>{member.description}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  </section>
)

Members.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
}

Members.defaultProps = {
  title: "",
  list: [],
}

export default Members
