import React from "react"
import Layout from "../components/layout"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Members from "../components/about/members"
import Outro from "../components/outro"
import About from "../components/about/about"
import Seo from "../components/seo"

const AboutPage = ({ data }) => {
  const { frontmatter } = data.mdx

  return (
    <Layout>
      <Seo title={frontmatter.title} description={frontmatter.description} />
      {frontmatter && <About key="#about" {...frontmatter} />}
      {frontmatter.members && (
        <Members key="#members" {...frontmatter.members} />
      )}
      {frontmatter.outro && <Outro key="#outro" {...frontmatter.outro} />}
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default AboutPage

export const pageQuery = graphql`
  query AboutTemplate($locale: String!) {
    mdx(
      fileAbsolutePath: { regex: "/static-pages/about/" }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        intro {
          paragraph
        }
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        members {
          title
          list {
            name
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            position
            # description
          }
        }
        outro {
          title
          buttonLeft {
            link
            caption
          }
        }
      }
    }
  }
`
