import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

const About = ({ title, intro, image }) => {
  return (
    <section id="about" className="section-about">
      <div className="shell">
        {title && (
          <header className="section-about-head">
            <h1
              dangerouslySetInnerHTML={{
                __html: title + `<span class="dotRed">.</span>`,
              }}
            />
          </header>
        )}
        <div className="section-about-inner">
          <aside className="section-about-content">
            {intro.map((paragraph, index) => (
              <p key={index} className="about-intro">
                {paragraph.paragraph}
              </p>
            ))}
          </aside>
          {image && (
            <aside className="section-about-aside">
              <div className="section-about-image">
                {image && (
                  <GatsbyImage
                    image={image.childImageSharp.gatsbyImageData}
                    alt="Enterprise Image"
                  />
                )}
              </div>
            </aside>
          )}
        </div>
      </div>
    </section>
  )
}

About.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.array,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

About.defaultProps = {
  title: "",
  intro: [],
}

export default About
